import ErrorPage from './index';
import { ReactComponent as UnhandledErrorIcon } from 'src/assets/icons/unhandled-error.svg';
import { UNHANDLED_ERROR_MESSAGE } from 'src/services/errorHandling/constants';
import styles from './index.module.scss';

const UnhandledError = () => {
  return (
    <ErrorPage
      subtitle={UNHANDLED_ERROR_MESSAGE}
      icon={<UnhandledErrorIcon className={styles.icon} />}
    />
  );
};

export default UnhandledError;
