import { Row, Col } from 'react-bootstrap';

import { useTranslation } from 'src/hooks/useTranslation';

import styles from './index.module.scss';

const NoData = ({ emptyMessage = "Sorry, we couldn't find any results" }) => {
  const { t } = useTranslation();

  return (
    <Row className={styles.hasNoDataRow}>
      <Col md={{ offset: 1, span: 10 }}>
        <header>
          <span className={styles.hasNoDataSpan}>{t(emptyMessage)}</span>
        </header>
      </Col>
    </Row>
  );
};

export default NoData;
