import { useLocation } from 'react-router-dom';
import { MdHistory } from 'react-icons/md';

import ModalComponent from 'src/components/Modal';
import LogsList from './LogsList';

import { useHandleScreenLogs } from 'src/components/Layout/hooks/useHandleScreenLogs';
import { ROUTES } from 'src/services/navigation/constants';

import styles from './LogsTrigger.module.scss';

const LogsTrigger = () => {
  const location = useLocation();

  const currentScreen = location.pathname;

  const shouldShowLogsBtn = ![
    ROUTES.PROFILE,
    ROUTES.ACCOUNT,
    ROUTES.NOT_FOUND,
    ROUTES.EMPTY,
    ROUTES.ERROR,
  ].includes(currentScreen);

  const {
    data: logsData,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    isLoading,
    handleOpenLogs,
    handleCancel,
    isModalShown,
  } = useHandleScreenLogs({ currentScreen });

  return (
    shouldShowLogsBtn && (
      <>
        <ModalComponent
          onHide={handleCancel}
          show={isModalShown}
          headerTitle="Activity logs"
          body={
            <LogsList
              isLoading={isLoading}
              isFetchingNextPage={isFetchingNextPage}
              isFetching={isFetching}
              hasNextPage={hasNextPage}
              fetchNextPage={fetchNextPage}
              logs={logsData?.pages?.reduce(
                (acc, curr) => [...acc, ...(curr?.data?.logs ?? [])],
                []
              )}
            />
          }
          isClosedIcon
          hasSubmitBtn={false}
          modalClassNames={{
            dialogClassName: styles.dialog,
            footerClassName: styles.footer,
          }}
        />
        <div className={styles.logsBtn}>
          <MdHistory onClick={handleOpenLogs} />
        </div>
      </>
    )
  );
};

export default LogsTrigger;
