import history from './history';
import { AUTH_LOGIN_URL, AUTH_LOGOUT_URL } from 'src/services/api/urls';
import { IDENTITY_SERVICE_URL } from 'src/config/constants';
import { ROUTES } from './constants';

export { history };

export const goToLogin = () => {
  const email = new URLSearchParams(window.location.search).get('email');
  const url = AUTH_LOGIN_URL;
  const newUrl = url.endsWith('/') ? url : `${url}/`;
  const loginURL = `${newUrl}?redirect_uri=${encodeURIComponent(
    window.location.href
  )}${email ? '&email=' + email : ''}`;
  window.location.replace(loginURL);
  return null;
};

export const goToLogout = () => {
  const url = AUTH_LOGOUT_URL;
  let newUrl = url.endsWith('/') ? url : `${url}/`;
  window.location.replace(
    `${newUrl}?redirect_uri=${encodeURIComponent(window.location.href)}`
  );
};

export const goToProfile = () => {};

(function () {
  const urlParams = new URLSearchParams(window.location.search);
  const redirect_uri = urlParams.get('redirect_uri');

  if (redirect_uri) {
    localStorage.setItem('redirect_uri', redirect_uri);
  }
})();

export const goTo = (path, options) => {
  const urlParams = new URLSearchParams(window.location.search);
  if (options && options.extendParams && Object.keys(options.extendParams)) {
    for (const param in options.extendParams) {
      if (param in options.extendParams) {
        const value = options.extendParams[param];
        urlParams.set(param, value);
      }
    }
  }
  const newPath =
    (path.endsWith('/') ? path : path + '/') +
    (urlParams ? '?' + urlParams : '');

  history.push(newPath);
  window.location.replace(newPath);
};

export const goToAccount = () => {
  const accountScreen = ROUTES.PROFILE;
  history.push(accountScreen);
  window.location.replace(accountScreen);
};

export const goToHome = () => {
  const homeScreen = ROUTES.ACCOUNT;
  history.push(homeScreen);
  window.location.replace(homeScreen);
};

export const goToNotFound = () => {
  history.push(ROUTES.EMPTY);
  window.location.replace(
    IDENTITY_SERVICE_URL.replace(/\/+$/, '') + ROUTES.NOT_FOUND
  );
};
