import { lazy, StrictMode, Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import * as Sentry from '@sentry/react';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import { ThemeProvider } from '@Symbyo360/web-design-kit';

import { ToastContainer } from 'src/services/toaster';
import Fallback from 'src/components/Fallback';
import PageSpinner from 'src/components/Spinner';

import amplify from 'src/services/amplify';
import { sentryInit } from 'src/config/sentry';
import './i18n';
import reportWebVitals from './reportWebVitals';
import ConfirmDialogProvider from 'src/components/ConfirmDialog/ConfirmDialogProvider';

import 'src/styles/index.scss';
import 'bootstrap/dist/css/bootstrap.min.css';
import '@Symbyo360/web-design-kit/dist/style.css';

const IdentityPortal = lazy(() => import('src/pages'));

const SentryErrorBoundary = Sentry.ErrorBoundary;

Sentry.init(sentryInit);
amplify.configure();

const root = ReactDOM.createRoot(document.getElementById('root'));

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      networkMode: 'always',
    },
  },
});

root.render(
  <StrictMode>
    <ThemeProvider>
      <QueryClientProvider client={queryClient}>
        <ReactQueryDevtools initialIsOpen={false} />
        <SentryErrorBoundary fallback={Fallback}>
          <ToastContainer
            autoClose={7e3}
            bodyClassName="text-break"
            containerId="toast-container"
            closeButton={
              <div>
                <AiOutlineCloseCircle
                  data-testid="toast-close-icon"
                  style={{ fontSize: '1.5rem', width: '2rem', height: '2rem' }}
                />
              </div>
            }
          />
          <ConfirmDialogProvider>
            <Suspense fallback={<PageSpinner isShown contained></PageSpinner>}>
              <IdentityPortal />
            </Suspense>
          </ConfirmDialogProvider>
        </SentryErrorBoundary>
      </QueryClientProvider>
    </ThemeProvider>
  </StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
