const LogRow = ({ item, style, className, itemClassName, columns }) => {
  return (
    <div
      className={className}
      style={{
        ...style,
        gridTemplateColumns: columns
          .map((column) => `${column.width}fr`)
          .join(' '),
        cursor: 'default',
      }}
    >
      {columns?.map((column) =>
        column.render({ item, className: itemClassName })
      )}
    </div>
  );
};

export default LogRow;
