import {
  useCallback,
  createContext,
  useContext,
  useState,
  useRef,
  cloneElement,
} from 'react';
import ModalComponent from 'src/components/Modal';
import styles from './index.module.scss';

const ConfirmDialogContext = createContext({ confirm: (cb) => cb });

export const useConfirm = () => useContext(ConfirmDialogContext);

export const useDisclosure = () => {
  const [isOpen, setIsOpen] = useState(false);
  const onOpen = useCallback(() => {
    if (!isOpen) setIsOpen(true);
  }, [isOpen]);
  const onClose = useCallback(() => {
    if (isOpen) setIsOpen(false);
  }, [isOpen]);
  const onToggle = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen]);

  return { isOpen, onOpen, onClose, onToggle };
};

export default function ConfirmDialogProvider({ children }) {
  const { isOpen, onOpen, onClose } = useDisclosure();

  const callbackRef = useRef(null);
  const optionsRef = useRef({});
  const argsRef = useRef([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [inputValues, setInputValues] = useState({});

  const confirm = useCallback(
    (cb, options) =>
      (...args) => {
        argsRef.current = args;
        callbackRef.current = cb;
        if (options) {
          optionsRef.current = options;
        }
        onOpen();
      },
    [onOpen]
  );

  const handleReset = useCallback(() => {
    onClose();
    setValidationErrors({});
    setInputValues({});
  }, [onClose]);

  const handleSubmitSuccess = useCallback(() => {
    if (typeof optionsRef.current?.onSuccess === 'function') {
      optionsRef.current.onSuccess();
    }
    handleReset();
  }, [handleReset]);

  const handleValidation = useCallback(() => {
    if (typeof optionsRef.current?.validate === 'function') {
      const errors = optionsRef.current?.validate(inputValues);

      if (Object.keys(errors).length) {
        if (typeof optionsRef.current?.onError === 'function') {
          optionsRef.current.onError();
        }
        setValidationErrors(errors);
        return false;
      }
    }
    return true;
  }, [inputValues]);

  const handleSubmit = useCallback(async () => {
    if (optionsRef.current?.keepOriginalArgs && argsRef.current?.length) {
      await callbackRef.current(...argsRef.current);
      handleSubmitSuccess();
      return;
    }
    if (handleValidation()) {
      await callbackRef.current(inputValues);
      handleSubmitSuccess();
    }
  }, [handleValidation, inputValues, handleSubmitSuccess]);

  const handleClose = useCallback(() => {
    callbackRef.current = null;

    if (typeof optionsRef.current?.onSuccess === 'function') {
      optionsRef.current.onSuccess();
    }

    handleReset();
  }, [handleReset]);

  return (
    <ConfirmDialogContext.Provider value={{ confirm }}>
      {isOpen ? (
        <ModalComponent
          headerTitle={optionsRef.current?.title ?? 'Confirm'}
          isClosedButton
          modalClassNames={{
            containerClassName: styles.modalContent,
            headerClassName: styles.modalHeader,
            titleClassName: styles.modalTitle,
            footerClassName: styles.modalFooter,
            bodyClassName: styles.modalBody,
          }}
          show={isOpen}
          cancelText="Cancel"
          actionText={optionsRef.current?.actionText ?? 'Confirm'}
          onSubmit={handleSubmit}
          onHide={handleClose}
          body={
            typeof optionsRef.current?.renderBody === 'function'
              ? cloneElement(optionsRef.current?.renderBody(), {
                  validationErrors,
                  inputValues,
                  setInputValues,
                })
              : optionsRef.current?.body ?? (
                  <p className="text-center">Are you sure?</p>
                )
          }
        />
      ) : null}
      {children}
    </ConfirmDialogContext.Provider>
  );
}
