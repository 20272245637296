import { useQuery } from '@tanstack/react-query';
import { handleError } from 'src/services/errorHandling';
import { getCognitoUser } from 'src/services/api/auth';

const useGetUserQuery = () => {
  return useQuery(['get-user'], getCognitoUser, {
    onError: (error) => {
      handleError(error);
    },
    retry: 0,
    staleTime: Infinity,
  });
};

export default useGetUserQuery;
