import { default as BootStrapButton } from 'react-bootstrap/Button';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import styles from './index.module.scss';
import { useTranslation } from 'src/hooks/useTranslation';
import { Spinner } from 'react-bootstrap';

/**
 * @param {(string|string[])} props.className
 * @param {Boolean} props.disabled
 * @callback props.onClick
 * @param {('primary'|'secondary'|'success'|'danger'|'warning'|'info'| 'light'|'dark')} props.variant
 */

function Button({ className, children, isLoading, disabled, ...props }) {
  const { t } = useTranslation();

  return (
    <BootStrapButton
      {...props}
      className={classnames(styles['cw-button'], className)}
      disabled={disabled || isLoading}
    >
      {isLoading && (
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      )}
      <span>{typeof children === 'string' ? t(children) : children}</span>
    </BootStrapButton>
  );
}

Button.defaultProps = {
  variant: 'primary',
};

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  permission: PropTypes.string,
  isLoading: PropTypes.bool,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'success',
    'danger',
    'warning',
    'info',
    'light',
    'dark',
    'link',
  ]).isRequired,
  children: PropTypes.node,
};

export default Button;
