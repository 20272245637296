import request, { getAccessToken } from 'src/lib/axios';
import { COGNITO_SERVICE_URL as COGNITO_URL } from 'src/config/constants';

export const getCognitoUser = async () => {
  const accessToken = await getAccessToken();

  return request
    .post(
      COGNITO_URL,
      {
        AccessToken: accessToken,
      },
      {
        headers: {
          'Content-Type': 'application/x-amz-json-1.1',
          'X-Amz-Target': 'AWSCognitoIdentityProviderService.GetUser',
        },
      }
    )
    .then((response) => {
      let user = {
        username: response.data.Username,
      };
      response.data.UserAttributes.forEach(({ Name, Value }) => {
        user[Name] = Value;
      });

      return user;
    });
};
