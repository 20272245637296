import { Container, Row, Col } from 'react-bootstrap';
import styles from './index.module.scss';
import withLayout from 'src/components/Layout';
import { useTranslation } from 'src/hooks/useTranslation';

const ErrorPage = ({ title, subtitle, icon, children, clickAction }) => {
  const { t } = useTranslation();

  return (
    <div className="w-100 h-100 bg-white">
      <Container
        className={styles.container}
        data-testid="error-page"
        onClick={clickAction}
        style={clickAction && { cursor: 'pointer' }}
      >
        <Row className={styles.content}>
          <Col xs={12}>
            {title && (
              <div className={styles.title} data-testid="error-title">
                {t(title)}
              </div>
            )}
            {icon}
            {subtitle && (
              <div className={styles.subtitle}>
                {typeof subtitle === 'string' ? t(subtitle) : subtitle}
              </div>
            )}
            <div>{children}</div>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default withLayout(ErrorPage, {
  navProps: { hideTabs: true },
});
