export const ROUTES = {
  LOGIN: '/login',
  LOGOUT: '/logout',
  LOGIN_SUCCESS: '/login-success',
  SINGUP: '/signup',
  SIGNUP_SUCCESS: '/signup-success',
  FORGOT_PASSWORD: '/forgot-password',
  FORGOT_PASSWORD_SUCCESS: '/forgot-password-success',
  RESET_PASSWORD: '/reset-password',
  CONFIRM_EMAIL: '/confirm-email',
  CONFIRM_SINGUP: '/confirm-signup',
  RENEWAL: '/login/renewal',
  NEW_PASSWORD: '/new-password',
  ACCOUNT: '/account/dashboard',
  USERS: '/admin/users',
  USER_PROFILE: '/admin/users/:orgId/:id/profile',
  USER_TRANSACTIONS: '/admin/users/:orgId/:id/transactions',
  USER_RESOURCES: '/admin/users/:orgId/:id/resources',
  PROFILE: '/account/profile',
  EMPTY: '/empty',
  NOT_FOUND: '/not-found',
  TAGS: '/admin/settings/tags',
  ERROR: '/error',
  RESOURCES: '/admin/settings/resources',
};
