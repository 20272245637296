import { Button } from 'react-bootstrap';
import ErrorPage from './index';
import { ReactComponent as NetworkErrorIcon } from 'src/assets/icons/network-error.svg';
import { INTERNET_ERROR_MESSAGE } from 'src/services/errorHandling/constants';
import { useTranslation } from 'src/hooks/useTranslation';
import styles from './index.module.scss';

const UnhandledError = () => {
  const reload = () => window.location.reload();

  const { t } = useTranslation();

  return (
    <ErrorPage
      title={INTERNET_ERROR_MESSAGE}
      icon={
        <NetworkErrorIcon
          className={styles.icon}
          data-testid="network-error-img"
        />
      }
    >
      <Button onClick={reload} data-testid="reload-btn">
        {t('Reload')}
      </Button>
    </ErrorPage>
  );
};

export default UnhandledError;
