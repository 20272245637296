import { format } from 'date-fns';

export const formateDatetime = (value) => {
  if (!value) {
    return '';
  }
  let date = value;

  if (!(date instanceof Date)) {
    date = new Date(date);
  }

  date = format(date, 'Pp');

  return date;
};
