import styles from './Ellipsis.module.scss';

const Ellipsis = ({ style }) => {
  return (
    <div
      className={styles.ldsEllipsis}
      style={style}
      data-testid="activity-indicator"
    >
      <div>
        <span />
        <span />
        <span />
        <span />
      </div>
    </div>
  );
};

export default Ellipsis;
