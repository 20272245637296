import { ROUTES } from 'src/services/navigation/constants';

const NODE_ENV = process.env.NODE_ENV;

export const IDENTITY_SERVICE_URL =
  process.env.REACT_APP_AUTH_SERVICE_URL &&
  process.env.REACT_APP_AUTH_SERVICE_URL.endsWith('/')
    ? process.env.REACT_APP_AUTH_SERVICE_URL
    : process.env.REACT_APP_AUTH_SERVICE_URL + '/';

export const ACCOUNTS_SERVICE_URL =
  process.env.REACT_APP_ACCOUNTS_SERVICE_URL &&
  process.env.REACT_APP_ACCOUNTS_SERVICE_URL.endsWith('/')
    ? process.env.REACT_APP_ACCOUNTS_SERVICE_URL
    : process.env.REACT_APP_ACCOUNTS_SERVICE_URL + '/';

export const COGNITO_SERVICE_URL = process.env.REACT_APP_COGNITO_URL;

export const ATOMICA_SERVICE_URL = process.env.REACT_APP_ATOMICA_BASE_URL;

assertEnvVar('REACT_APP_TITLE');
assertEnvVar('REACT_APP_AUTH_SERVICE_URL');
assertEnvVar('REACT_APP_COGNITO_URL');
assertEnvVar('REACT_APP_ATOMICA_BASE_URL');

export const ACCOUNTS_PROFILE_SETTINGS_PATH = ROUTES.SETTINGS;

export const ACCOUNTS_PROFILE_SETTINGS_URL =
  ACCOUNTS_SERVICE_URL.slice(0, -1) + ACCOUNTS_PROFILE_SETTINGS_PATH;

export const IDENTITY_ROUTES = {
  LOGIN: {
    path: '/login',
    exact: true,
    isPrivate: false,
  },
  LOGOUT: {
    path: '/logout',
    exact: true,
    isPrivate: false,
  },
  SINGUP: {
    path: '/signup',
    exact: true,
    isPrivate: false,
  },
  SIGNUP_SUCCESS: {
    path: '/signup-success',
    exact: true,
    isPrivate: false,
  },
  FORGOT_PASSWORD: {
    path: '/forgot-password',
    exact: true,
    isPrivate: false,
  },
  FORGOT_PASSWORD_SUCCESS: {
    path: '/forgot-password-success',
    exact: true,
    isPrivate: false,
  },
  RESET_PASSWORD: {
    path: '/reset-password',
    exact: true,
    isPrivate: false,
  },
  CONFIRM_EMAIL: {
    path: '/confirm-email',
    exact: true,
    isPrivate: false,
  },
  CONFIRM_SINGUP: {
    path: '/confirm-signup',
    exact: true,
    isPrivate: false,
  },
  RENEWAL: {
    path: '/login/renewal',
    exact: true,
    isPrivate: false,
  },
  NEW_PASSWORD: {
    path: '/new-password',
    exact: true,
    isPrivate: false,
  },
};

function assertEnvVar(path) {
  if (NODE_ENV === 'development') {
    // eslint-disable-next-line no-console
    console.assert(
      process.env[path],
      `environment variable at path "process.env.%s" is undefined`,
      path
    );
  }
}

export const SESSION_EXPIRATION_TIME = 'session_expiration_time';

export const PAGE_ACTIVE_EVENTS = [
  'click',
  'mouseup',
  'mousedown',
  'keyup',
  'keydown',
  'scroll',
  'wheel',
];
