import { IDENTITY_SERVICE_URL as IDENTITY_URL } from 'src/config/constants';

// Auth
export const AUTH_LOGOUT_URL = `${IDENTITY_URL}logout`;
export const AUTH_LOGIN_URL = `${IDENTITY_URL}login`;

// admin
export const USERS_LIST_ADMIN_URL = 'admin/users';
export const USER_TRANSACTIONS_ADMIN_URL = 'admin/users/{id}/transactions';
export const UPDATE_USER_TRANSACTION_STATUS_ADMIN_URL =
  'admin/users/{userId}/transactions/{transactionId}';
export const USER_INFO_ADMIN_URL = 'admin/users/{id}';
export const USER_RESOURCES_ADMIN_URL = 'admin/users/{id}/resources';
export const RESOURCES_ADMIN_URL = 'admin/resources';
export const UPDATE_USER_RESOURCE_ADMIN_URL =
  'admin/users/{userId}/resources/{resourceName}';
export const LIST_TAGS_URL = 'admin/tags';
export const USERS_LOGS_URL = 'admin/logs/organizations';
export const ORGANIZATION_PROFILE_LOGS_URL =
  'admin/logs/organizations/{OrgId}/Users';
export const ORGANIZATION_TRANSACTIONS_LOGS_URL =
  'admin/logs/organizations/{OrgId}/Transactions';
export const ORGANIZATION_RESOURCES_LOGS_URL =
  'admin/logs/organizations/{OrgId}/Resources';
export const RESOURCES_LOGS_URL = 'admin/logs/resources';
export const TAGS_LOGS_URL = 'admin/logs/tags';

// account
export const DOWNLOAD_APP_URL = '/download';
