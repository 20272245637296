import { Fragment } from 'react';
import { Container, Spinner } from 'react-bootstrap';

import styles from './index.module.scss';

const PageSpinner = (props) => {
  if (!props.isShown) {
    return null;
  }

  const C = props.contained ? Container : Fragment;

  return (
    <C className={styles.container} style={props.style} fluid>
      <Spinner animation="border" />
    </C>
  );
};

export default PageSpinner;
