import { Fragment } from 'react';
import { InfiniteLoader, List, AutoSizer } from 'react-virtualized';
import PropTypes from 'prop-types';

import ActivityIndicator from 'src/components/ActivityIndicator';
import NoData from './NoData';

import { useTranslation } from 'src/hooks/useTranslation';

import styles from './index.module.scss';

const Body = ({
  data,
  renderRow,
  renderRowPlaceHolder,
  height,
  isLoadingMore,
  registerChild,
  onRowsRendered,
  emptyMessage,
  columns,
  hasNoSearchResults,
}) => {
  return data?.length > 0 ? (
    <AutoSizer disableHeight>
      {({ width }) => (
        <List
          ref={registerChild}
          height={height}
          onRowsRendered={onRowsRendered}
          rowCount={data?.length + 1}
          rowHeight={60}
          width={width}
          rowRenderer={({ index, key, style }) => {
            if (index >= data?.length) {
              return isLoadingMore ? (
                <Fragment key={key}>
                  {renderRowPlaceHolder({
                    style,
                    className: styles.skeletonLoader,
                  })}
                </Fragment>
              ) : null;
            }

            const item = data[index];
            return (
              item && (
                <Fragment key={key}>
                  {renderRow({
                    item,
                    style,
                    className: styles.vTableRow,
                    columns,
                    itemClassName: styles.vTableRowItem,
                    index: key,
                  })}
                </Fragment>
              )
            );
          }}
        />
      )}
    </AutoSizer>
  ) : hasNoSearchResults ? (
    <NoData />
  ) : (
    <NoData emptyMessage={emptyMessage} />
  );
};

const BodyRenderer = ({
  isLoading,
  data,
  renderRow,
  renderRowPlaceHolder,
  height,
  isLoadingMore,
  registerChild,
  onRowsRendered,
  emptyMessage,
  columns,
  hasNoSearchResults,
}) => {
  return isLoading ? (
    <ActivityIndicator type="Table" />
  ) : (
    <Body
      renderRow={renderRow}
      renderRowPlaceHolder={renderRowPlaceHolder}
      height={height}
      isLoadingMore={isLoadingMore}
      registerChild={registerChild}
      onRowsRendered={onRowsRendered}
      data={data}
      emptyMessage={emptyMessage}
      columns={columns}
      hasNoSearchResults={hasNoSearchResults}
    />
  );
};

const Header = ({ columns, skipColumnHeaderTranslation }) => {
  const { t } = useTranslation();
  return (
    <div
      className={styles.vTableHeader}
      style={{
        gridTemplateColumns: columns
          .map((column) =>
            column.absoluteWidth ? column.absoluteWidth : `${column.width}fr`
          )
          .join(' '),
      }}
    >
      {columns.map((column) => (
        <p key={column.title} className={styles.vTableHeaderItem}>
          {skipColumnHeaderTranslation ? column.title : t(column.title)}
        </p>
      ))}
    </div>
  );
};

const ResizableVirtualizedTable = ({
  data,
  loadMoreRows,
  columns,
  isLoading,
  renderRow,
  renderRowPlaceHolder,
  height,
  isLoadingMore,
  emptyMessage,
  infiniteLoaderRef,
  noSearchCondition,
  skipColumnHeaderTranslation,
}) => {
  const hasNoSearchResults =
    !noSearchCondition && !isLoading && data?.length === 0;

  return (
    <InfiniteLoader
      isRowLoaded={({ index }) => index < data?.length}
      loadMoreRows={loadMoreRows}
      rowCount={1000000}
      ref={infiniteLoaderRef}
    >
      {({ onRowsRendered, registerChild }) => (
        <div className={styles.vTableContainer}>
          <div className={styles.vTable}>
            <Header
              columns={columns}
              skipColumnHeaderTranslation={skipColumnHeaderTranslation}
              key={JSON.stringify(columns)}
            />
            <BodyRenderer
              isLoading={isLoading}
              renderRow={renderRow}
              renderRowPlaceHolder={renderRowPlaceHolder}
              height={height}
              isLoadingMore={isLoadingMore}
              registerChild={registerChild}
              onRowsRendered={onRowsRendered}
              data={data}
              emptyMessage={emptyMessage}
              columns={columns}
              hasNoSearchResults={hasNoSearchResults}
            />
          </div>
        </div>
      )}
    </InfiniteLoader>
  );
};

ResizableVirtualizedTable.defaultProps = {
  columns: [],
  isLoading: false,
  height: (window.innerHeight ?? 800) - 310,
  emptyMessage: 'There are no users',
};

ResizableVirtualizedTable.propTypes = {
  data: PropTypes.array,
  columns: PropTypes.array,
  isLoading: PropTypes.bool.isRequired,
  isLoadingMore: PropTypes.bool,
  renderRow: PropTypes.func.isRequired,
  renderRowPlaceHolder: PropTypes.func.isRequired,
  loadMoreRows: PropTypes.func,
  height: PropTypes.number,
  emptyMessage: PropTypes.string,
  infiniteLoaderRef: PropTypes.object,
  noSearchCondition: PropTypes.bool,
  skipColumnHeaderTranslation: PropTypes.bool,
};

export default ResizableVirtualizedTable;
