import { Col, Row } from 'react-bootstrap';
import Skeleton from 'react-loading-skeleton';

import styles from './Form.module.scss';

const Form = () => {
  return (
    <Row className={styles.form} data-testid="activity-indicator">
      <Col xl={4} className={styles.title}>
        <Skeleton count={1} />
      </Col>
      <Col xl={12}>
        <div className={styles.fields}>
          <Skeleton count={2} />
          <Skeleton count={2} />
        </div>
      </Col>
      <Col xl={4} className={styles.title}>
        <Skeleton count={1} />
      </Col>
      <Col xl={12}>
        <div className={styles.fields}>
          <Skeleton count={2} />
          <Skeleton count={2} />
        </div>
      </Col>
    </Row>
  );
};

export default Form;
