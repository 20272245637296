import { useState } from 'react';
import UnhandledError from 'src/pages/Error/UnhandledError';
import NetworkError from 'src/pages/Error/NetworkError';
import { reportError } from 'src/services/errorHandling';
import { BrowserRouter as Router } from 'react-router-dom';
import { Modal } from 'react-bootstrap';
import styles from './index.module.scss';

const DevErrorFallback = ({ error, componentStack }) => {
  const [show, setShow] = useState(true);

  const closeModal = () => setShow(false);

  return (
    <Modal
      className={styles['error-modal']}
      show={show}
      size="xl"
      onHide={closeModal}
      backdropClassName={styles.backdrop}
    >
      <Modal.Header closeButton>
        <Modal.Title>Something went wrong: {error.message}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <pre>{error.message}</pre>
        <p>Component stack:</p>
        <pre>{componentStack}</pre>
      </Modal.Body>
    </Modal>
  );
};

const Fallback = (error) => {
  reportError(error, 'react fallback');

  const Error =
    error?.error?.name === 'ChunkLoadError' ||
    error?.error?.code === 'CSS_CHUNK_LOAD_FAILED'
      ? NetworkError
      : UnhandledError;

  return (
    <Router>
      <Error />

      {process.env.NODE_ENV === 'development' && (
        <DevErrorFallback
          error={error?.error}
          componentStack={error?.componentStack}
        />
      )}
    </Router>
  );
};

export default Fallback;
