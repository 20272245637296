import { ROUTES } from 'src/services/navigation/constants';
import { replaceRouteParams } from 'src/utils';

export const useReplaceUserRoutesParams = ({ id, orgId }) => {
  const userProfileRoute = replaceRouteParams(ROUTES.USER_PROFILE, {
    id,
    orgId,
  });

  const userTransactionsRoute = replaceRouteParams(ROUTES.USER_TRANSACTIONS, {
    id,
    orgId,
  });

  const userResourcesRoute = replaceRouteParams(ROUTES.USER_RESOURCES, {
    id,
    orgId,
  });

  return { userProfileRoute, userTransactionsRoute, userResourcesRoute };
};
