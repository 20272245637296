import { useCallback } from 'react';
import { Row } from 'react-bootstrap';

import ResizableVirtualizedTable from 'src/components/ResizableVirtualizedTable';
import LogRow from './LogRow';
import ActivityIndicator from 'src/components/ActivityIndicator';

import { formateDatetime } from 'src/services/intl/datetimeformat';

import styles from './LogsList.module.scss';

const ItemRenderer = ({ data, className }) => {
  return <p className={className}>{data || '-'}</p>;
};

const columns = [
  {
    title: 'User',
    render: ({ item, className }) => (
      <ItemRenderer data={item?.u?.n} className={className} />
    ),
    width: 2,
    align: 'left',
  },
  {
    title: 'Date',
    render: ({ item, className }) => (
      <ItemRenderer data={formateDatetime(item?.t)} className={className} />
    ),
    width: 2,
    align: 'left',
  },
  {
    title: 'Action',
    render: ({ item, className }) => (
      <ItemRenderer data={item?.a} className={className} />
    ),
    width: 2,
    align: 'left',
  },
];

const RowPlaceHolder = ({ style, className }) => {
  return (
    <div style={style} className={className}>
      <ActivityIndicator
        type="Ellipsis"
        style={{
          height: '50px',
          display: 'flex',
          padding: '20px',
        }}
      />
    </div>
  );
};

const LogsList = ({
  isLoading,
  isFetchingNextPage,
  isFetching,
  hasNextPage,
  fetchNextPage,
  logs,
}) => {
  const loadMoreRows = useCallback(() => {
    if (isFetching || isFetchingNextPage || !hasNextPage) {
      return;
    }
    fetchNextPage();
  }, [isFetching, isFetchingNextPage, hasNextPage, fetchNextPage]);

  return (
    <Row className={styles.tableContainer}>
      <ResizableVirtualizedTable
        emptyMessage="There are no activity logs"
        columns={columns}
        isLoading={isLoading}
        isLoadingMore={isFetchingNextPage}
        loadMoreRows={loadMoreRows}
        data={logs}
        height={(window.innerHeight ?? 800) - 270}
        renderRow={({ item, style, className, itemClassName }) => {
          return (
            <LogRow
              item={item}
              style={style}
              className={className}
              itemClassName={itemClassName}
              columns={columns}
            />
          );
        }}
        renderRowPlaceHolder={({ style, className }) => (
          <RowPlaceHolder style={style} className={className} />
        )}
      />
    </Row>
  );
};

export default LogsList;
