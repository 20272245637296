import { Modal } from 'react-bootstrap';
import Button from '../Button';
import { useTranslation } from 'src/hooks/useTranslation';

const ModalComponent = ({
  onHide,
  show = false,
  body,
  headerTitle = '',
  isClosedButton = false,
  onSubmit,
  actionText,
  cancelText,
  modalClassNames,
  isClosedIcon = false,
  ModalIcon,
  disabled = false,
  hasSubmitBtn = true,
  isSubmitting = false,
}) => {
  const { t } = useTranslation();

  return (
    <Modal
      show={show}
      centered
      aria-labelledby="contained-modal-title-vcenter"
      onHide={onHide}
      contentClassName={modalClassNames?.containerClassName}
      dialogClassName={modalClassNames?.dialogClassName}
      data-testid="modal-form"
    >
      <Modal.Header
        closeButton={isClosedIcon}
        className={modalClassNames?.headerClassName}
      >
        <Modal.Title
          id="contained-modal-title-vcenter"
          className={modalClassNames?.titleClassName}
        >
          {ModalIcon ? (
            <div
              className={modalClassNames?.iconClassName}
              data-testid="modal-icon"
            >
              <ModalIcon />
            </div>
          ) : null}
          {t(headerTitle)}
        </Modal.Title>
      </Modal.Header>

      <Modal.Body className={modalClassNames?.bodyClassName}>{body}</Modal.Body>
      <Modal.Footer className={modalClassNames?.footerClassName}>
        {isClosedButton && (
          <Button variant="danger" onClick={onHide}>
            {t(cancelText) || t('Cancel')}
          </Button>
        )}
        {hasSubmitBtn && (
          <Button
            variant="primary"
            onClick={onSubmit || onHide}
            disabled={disabled || isSubmitting}
            data-testid="submit"
            isSubmitting={isSubmitting}
          >
            {t(actionText) || t('Save')}
          </Button>
        )}
      </Modal.Footer>
    </Modal>
  );
};

export default ModalComponent;
