import { Row, Col, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';

import { ReactComponent as Logout } from 'src/assets/icons/logout.svg';
import AtomicaLogo from 'src/components/Logo/AtomicaLogo';
import { ReactComponent as ProfileIcon } from 'src/assets/icons/profile.svg';
import LogsTrigger from './LogsTrigger';

import { goToLogout } from 'src/services/navigation';
import { useTranslation } from 'src/hooks/useTranslation';
import { ROUTES } from 'src/services/navigation/constants';
import useGetUserQuery from 'src/hooks/useGetUserQuery';

import styles from './NavBar.module.scss';

const TABS = {
  admin: {
    tabs: [
      {
        name: 'Users',
        path: ROUTES.USERS,
        base: ROUTES.USERS,
      },
      {
        name: 'Settings',
        path: ROUTES.RESOURCES,
        base: ROUTES.RESOURCES,
      },
    ],
  },
  account: {
    tabs: [
      {
        name: 'Overview',
        path: ROUTES.ACCOUNT,
        base: ROUTES.ACCOUNT,
      },
      {
        name: 'Profile',
        path: ROUTES.PROFILE,
        base: ROUTES.PROFILE,
      },
    ],
  },
};

const getNavTabs = () => {
  const portalName = window.location.pathname.split('/')[1];
  return TABS[portalName] || TABS['account'];
};

const Navbar = ({ hideTabs = false }) => {
  const { data: user } = useGetUserQuery() || {};
  const hasName = user?.given_name || user?.FirstName;
  const navbarTabs = getNavTabs();

  const { t } = useTranslation();

  return (
    <Row className={styles.navbar}>
      <Col xs={3} className={styles.logoCol}>
        <Link className={styles.logoLink} to={ROUTES.ACCOUNT}>
          <AtomicaLogo data-testid="app-logo" />
        </Link>
      </Col>

      <Col xs={5} className={styles.tabsCol}>
        <Nav className="justify-content-center" data-testid="tabs">
          {!hideTabs &&
            navbarTabs?.tabs.map((tab, idx) => {
              return (
                <Nav.Item key={idx} data-testid={`tab-item-${idx + 1}`}>
                  <Nav.Link
                    as={Link}
                    to={tab.path}
                    active={location.pathname.startsWith(tab.base)}
                  >
                    {t(tab.name)}
                  </Nav.Link>
                </Nav.Item>
              );
            })}
        </Nav>
      </Col>

      <Col xs={4} className={styles.rightMenu}>
        <LogsTrigger />

        <Nav className={styles.nav}>
          <Nav.Item className={styles.avatar} data-testid="profile-img">
            {hasName && <ProfileIcon data-testid="profile-logo" />}
          </Nav.Item>

          {hasName && (
            <Nav.Item className={styles.username} data-testid="profile-section">
              <div>{t('Welcome')}</div>
              <div data-testid="profile-name">
                {user?.given_name
                  ? user?.given_name + ' ' + user?.family_name
                  : user?.FirstName
                  ? user?.FirstName + ' ' + user?.LastName
                  : user?.Value}
              </div>
            </Nav.Item>
          )}

          <Nav.Item
            className={styles.logout}
            onClick={goToLogout}
            data-testid="logout-btn"
          >
            <Logout />
          </Nav.Item>
        </Nav>
      </Col>
    </Row>
  );
};

export default Navbar;
