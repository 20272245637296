import request from 'src/lib/axios';
import {
  USERS_LOGS_URL,
  ORGANIZATION_PROFILE_LOGS_URL,
  RESOURCES_LOGS_URL,
  TAGS_LOGS_URL,
  ORGANIZATION_TRANSACTIONS_LOGS_URL,
  ORGANIZATION_RESOURCES_LOGS_URL,
} from './urls';

const getSystemLogs = async (
  url,
  { filters, pageNumber, pageSize, orgId },
  signal
) => {
  const payload = {
    pageNumber: pageNumber || 1,
    pageSize,
    filterBy: filters ?? {},
  };
  const requestUrl = orgId ? url.replace('{OrgId}', orgId) : url;

  return request.post(requestUrl, payload, signal);
};

export const getOrganizationsLogsApi = async (
  { filters, pageNumber, pageSize },
  signal
) => {
  return getSystemLogs(
    USERS_LOGS_URL,
    { filters, pageNumber, pageSize },
    signal
  );
};

export const getOrganizationProfileLogsApi = async (
  { filters, pageNumber, pageSize, orgId },
  signal
) => {
  return getSystemLogs(
    ORGANIZATION_PROFILE_LOGS_URL,
    { filters, pageNumber, pageSize, orgId },
    signal
  );
};

export const getOrganizationTransactionsLogsApi = async (
  { filters, pageNumber, pageSize, orgId },
  signal
) => {
  return getSystemLogs(
    ORGANIZATION_TRANSACTIONS_LOGS_URL,
    { filters, pageNumber, pageSize, orgId },
    signal
  );
};

export const getOrganizationResourcesLogsApi = async (
  { filters, pageNumber, pageSize, orgId },
  signal
) => {
  return getSystemLogs(
    ORGANIZATION_RESOURCES_LOGS_URL,
    { filters, pageNumber, pageSize, orgId },
    signal
  );
};

export const getResourcesLogsApi = async (
  { filters, pageNumber, pageSize },
  signal
) => {
  return getSystemLogs(
    RESOURCES_LOGS_URL,
    { filters, pageNumber, pageSize },
    signal
  );
};

export const getTagsLogsApi = async (
  { filters, pageNumber, pageSize },
  signal
) => {
  return getSystemLogs(
    TAGS_LOGS_URL,
    { filters, pageNumber, pageSize },
    signal
  );
};
