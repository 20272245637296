import axios from 'axios';
import Cookies from 'js-cookie';
import { goToLogin } from 'src/services/navigation';
import {
  ATOMICA_SERVICE_URL,
  COGNITO_SERVICE_URL as COGNITO_URL,
} from 'src/config/constants';
import { refreshUserSession } from 'src/services/amplify/auth';

import { isTokenExpired, decodeJWT, sendSentryUrl } from 'src/utils';

const axiosInstance = axios.create({
  baseURL: `${ATOMICA_SERVICE_URL}`,
});

//  request urls don't need auth
const urlExceptions = [COGNITO_URL];

const handleError = (config, resolve) => {
  goToLogin();
  if (resolve && config) {
    return resolve(config);
  }
  return;
};

const checkAccessTokenValidity = (accessToken) => {
  const jwt = decodeJWT(accessToken);
  const isAccessTokenInvalid = isTokenExpired(jwt);
  return isAccessTokenInvalid;
};

const configAccessToken = (config, resolve, accessToken) => {
  config.headers.Authorization = 'Bearer ' + accessToken;

  return resolve(config);
};

const resolveToken = async (config, resolve) => {
  const session = await refreshUserSession();

  if (!session || !session?.accessToken?.jwtToken) {
    return handleError(resolve);
  }

  return configAccessToken(config, resolve, session?.accessToken?.jwtToken);
};

axiosInstance.interceptors.request.use(function (config) {
  return new Promise((resolve) => {
    const accessToken = Cookies.get('at');
    const isAccessTokenInvalid = checkAccessTokenValidity(accessToken);
    // if token is invalid
    if (isAccessTokenInvalid) {
      return resolveToken(config, resolve);
    }

    if (urlExceptions.some((url) => config.url?.includes(url))) {
      return resolve(config);
    }
    return configAccessToken(config, resolve, accessToken);
  });
});

axiosInstance.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    sendSentryUrl(error);
    return Promise.reject(error);
  }
);

export default axiosInstance;

export const getAccessToken = async () => {
  let accessToken = Cookies.get('at');
  const isAccessTokenInvalid = checkAccessTokenValidity(accessToken);
  if (isAccessTokenInvalid) {
    const session = await refreshUserSession();

    if (!session?.accessToken?.jwtToken) return null;

    return session.accessToken.jwtToken;
  }

  return accessToken;
};
