import jwtDecode from 'jwt-decode';
import { PhoneNumberUtil } from 'google-libphonenumber';
import { TOKENS_ERRORS } from 'src/services/errorHandling/constants';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/react';
import { exceptionTypes } from './constants';

const phoneUtil = PhoneNumberUtil.getInstance();

export function phoneNumberValidation(val) {
  if (!val) {
    return true;
  }

  if (val.length < 4) return false;

  try {
    const tempValue = val.startsWith('+') ? val : `+${val}`;
    const parsed = phoneUtil.parse(tempValue);
    return phoneUtil.isValidNumber(parsed);
  } catch (error) {
    return false;
  }
}

export const omitNull = (obj) => {
  for (let key in obj) {
    if (obj[key] === null) {
      delete obj[key];
      continue;
    }

    if (typeof obj === 'object' && obj !== null) {
      obj[key] = omitNull(obj[key]);
      continue;
    }
  }

  return obj;
};

/**
 * @param {String} value
 * @param {String} type
 * @param {Number} precision
 * @returns String
 */
export const formatNumber = (value, type, precision) => {
  if (!value && value !== 0) {
    return '';
  }

  value =
    type === 'float'
      ? precision
        ? parseFloat(value || '0').toFixed(precision)
        : parseFloat(value || '0').toString()
      : parseInt(value).toString();

  if (isNaN(value)) return '0';

  return value;
};

/**
 * @param {Number} value
 * @param {Number} min
 * @param {Number} max
 * @returns Number
 */
export const changeValueToBoundingLimits = (value, min, max) => {
  let newValue = value;

  if ((!!max || max === 0) && newValue > max) newValue = max;
  if ((!!min || min === 0) && newValue < min) newValue = min;

  return newValue;
};

/**
 * @param {Number} value
 * @param {{min: number, max: number,minDecimals: number , maxDecimals: number }} options
 * @returns Boolean
 */
export const checkNumberLength = ({
  value,
  min = 0,
  max = 7,
  minDecimals = 0,
  maxDecimals = 2,
}) => {
  const bounds = `{${min},${max}}`;
  const decimalsBounds = `{${minDecimals},${maxDecimals}}`;
  const regex = new RegExp(
    `^-?(\\d${bounds})(\\.(\\d${decimalsBounds}))?$`,
    'g'
  );

  return regex.test(String(value));
};

export const decodeJWT = (t) => {
  try {
    return jwtDecode(t);
  } catch (e) {
    return null;
  }
};

export function isTokenExpired(jwt) {
  if (jwt && jwt.exp) {
    const t = new Date().getTime() / 1000;
    return t > jwt.exp;
  }
  return true;
}

export function isValidDate(d) {
  return d instanceof Date && !isNaN(d);
}

const splitCamelCase = (text) => text?.replace(/([a-z0-9])([A-Z])/g, '$1 $2');

const capitalizePhraseFirstLetter = (text) => {
  return text?.charAt(0).toUpperCase() + text?.toLowerCase().slice(1);
};

export const formatNonSpacedText = (text) =>
  capitalizePhraseFirstLetter(splitCamelCase(text));

export function isProduction() {
  return ['true', true].includes(process.env.REACT_APP_IS_PRODUCTION);
}

export function sendSentryUrl(error) {
  const accessToken = Cookies.get('at');
  const userId = decodeJWT(accessToken)?.username;
  const status = error?.response?.status;

  const isSentryUrlSent =
    error &&
    userId &&
    status !== 401 &&
    !TOKENS_ERRORS.includes(
      error?.response?.data?.message?.toLocaleLowerCase()
    );

  if (isSentryUrlSent) {
    Sentry.captureException(error, {
      contexts: {
        payload: {
          'Response Error': error?.response?.data?.Message || error?.message,
          url: error?.config?.url || error?.response?.config?.url,
          method: error?.config?.method || error?.response?.config?.method,
          'Cognito Id': userId,
          'Response status':
            status || '' + ' ' + error?.response?.statusText || '',
        },
      },
    });
  }
}

export const filterObject = (
  obj,
  { includeParams = [], excludeParams = [] } = {}
) => {
  const infiniteScrollExcludeParams = ['PageNumber', 'pageSize'];

  return Object.entries(obj).reduce((prev, [key, value]) => {
    const whiteListed = !includeParams.length || includeParams.includes(key);
    const blackListed = excludeParams.includes(key);
    const globalBlackListed = infiniteScrollExcludeParams.includes(key);
    const isEmpty = value === null || value === undefined;
    if (!isEmpty && !blackListed && !globalBlackListed && whiteListed) {
      prev[key] = value;
    }
    return prev;
  }, {});
};

export function handleValidationError(type) {
  return exceptionTypes[type] || type;
}

export const replaceRouteParams = (route, params) => {
  return route.replace(/:\w+/g, (match) => params[match.slice(1)] || '');
};

export const generateQueryObj = (paramsString) => {
  const result = {};
  for (const [key, value] of new URLSearchParams(paramsString).entries()) {
    result[key] = value;
  }
  return result;
};

export const generateQueryString = (paramsObj) => {
  Object.entries(paramsObj).forEach(([key, value]) => {
    if (value === null || value === undefined) {
      delete paramsObj[key];
    }
  });
  const qs = new URLSearchParams(paramsObj).toString();
  const decodedParamsString = qs.replace(/\+/g, '%20');

  return decodedParamsString.length
    ? '?' + decodedParamsString
    : decodedParamsString;
};
