import { useMemo } from 'react';
import Skeleton from 'react-loading-skeleton';

import Table from './components/Table';
import Ellipsis from './components/Ellipsis';
import Form from './components/Form';

const types = {
  Table,
  Ellipsis,
  Form,
};

const ActivityIndicator = ({ type, style, ...props }) => {
  const [Comp] = useMemo(() => [type in types ? types[type] : null], [type]);

  if (Comp) {
    return <Comp style={style} />;
  }

  return <Skeleton {...props} />;
};

export default ActivityIndicator;
