import { Auth } from 'aws-amplify';
import Cookies from 'js-cookie';
import * as Sentry from '@sentry/react';
import { ROUTES } from '../navigation/constants';

const NEW_PASSWORD_REQUIRED = 'NEW_PASSWORD_REQUIRED';

const signupMetadata = (email) => {
  const url = `${window.location.origin}${ROUTES.LOGIN}/?email=${email}`;

  return {
    url_origin: window.location.origin,
    redirect_uri: encodeURIComponent(url),
  };
};

export const getUser = async () => {
  const user = await Auth.currentAuthenticatedUser();

  Cookies.set('at', user.signInUserSession?.accessToken?.jwtToken || '', {
    domain: '',
    sameSite: 'Lax',
  });

  return user;
};

export const refreshUserSession = async () => {
  try {
    const currentSession = await Auth.currentSession();
    const { accessToken } = currentSession;

    Cookies.set('at', accessToken?.jwtToken || '', {
      domain: window.location.href.includes('360courier.net')
        ? '.360courier.net'
        : '',
      sameSite: 'Lax',
    });

    return currentSession;
  } catch (error) {
    Sentry.captureException(error);

    Cookies.remove('at', {
      domain: window.location.href.includes('360courier.net')
        ? '.360courier.net'
        : '',
    });

    return null;
  }
};

export const completeNewPassword = async (
  username,
  oldPassword,
  newPassword
) => {
  let user = await Auth.signIn(username, oldPassword);

  const userAttributes = user.challengeParam.userAttributes;
  return Auth.completeNewPassword(user, newPassword, {
    given_name: userAttributes.given_name,
    family_name: userAttributes.family_name,
  });
};

export const signUp = async ({
  recaptchaToken,
  password,
  email: username,
  family_name,
  given_name,
  phone_number,
}) => {
  const payload = {
    username,
    password,
    attributes: {
      family_name,
      given_name,
      phone_number,
    },
    validationData: {
      recaptchaToken,
    },
    clientMetadata: signupMetadata(username),
  };

  // const username = uuidv4();
  // NOTE: temprory remove custom fields
  const user = await Auth.signUp(payload);
  return user;
};

export const signIn = async (username, password) => {
  const user = await Auth.signIn(username, password);
  if (user.challengeName != NEW_PASSWORD_REQUIRED) {
    await getUser();
  }
  return user;
};

export const signInWithGoogle = async () => {
  const auth = await Auth.federatedSignIn({ provider: 'Google' });
  return auth;
};

export const forgotPassword = async (email) => {
  return Auth.forgotPassword(email, {
    url_origin: window.location.origin,
  });
};

export const resetPassword = async (values) => {
  const { email, code, password } = values;
  return Auth.forgotPasswordSubmit(email, code, password);
};

export const confirmSignup = async (data) => {
  return Auth.confirmSignUp(data.username, data.code);
};

export const resendConfirmSignup = async ({ username, email }) => {
  return Auth.resendSignUp(username, signupMetadata(email || username));
};

export const signOut = async () => {
  Cookies.remove('at', {
    domain: window.location.href.includes('360courier.net')
      ? '.360courier.net'
      : '',
  });

  const response = await Auth.signOut();

  return response || null;
};
