import { Integrations } from '@sentry/tracing';
import packageJson from '../../package.json';


const SENTRY_KEY = process.env.REACT_APP_SENTRY_DSN;
function isSentryEnabled() {
  const SENTRY_ENABLED = process.env.REACT_APP_SENTRY_IS_ENABLED;
  return SENTRY_ENABLED?.toLowerCase() === 'true' && SENTRY_KEY;
}
export const sentryInit = isSentryEnabled()
  ? {
      dsn: SENTRY_KEY,
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0,
      release: `${packageJson.name}@${packageJson.version}`,
      environment: process.env.NODE_ENV,
    }
  : {};
