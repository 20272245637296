import { Container } from 'react-bootstrap';

import styles from './Layout.module.scss';
import Navbar from './NavBar';

const Layout = ({ children, settings = {} }) => {
  return (
    <Container className={styles.container} fluid>
      <Navbar {...settings?.navProps} />
      <Container className={styles.mainContainer} fluid>
        {children}
      </Container>
    </Container>
  );
};

export default Layout;
