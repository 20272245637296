import toaster from 'src/services/toaster';
import {
  INTERNET_ERROR_MESSAGE,
  TOKENS_ERRORS,
  UNAUTHORIZED_ERROR_MESSAGE,
  USER_IS_BLOCKED_ERROR,
  USER_IS_BLOCKED_MESSAGE,
} from 'src/services/errorHandling/constants';
import { goToLogin, goToAccount, goToNotFound } from 'src/services/navigation';
import { t } from 'src/hooks/useTranslation';

const handleHttpError = (error, options = {}) => {
  const status = error?.response?.status;

  if (
    status === 400 &&
    TOKENS_ERRORS.includes(error?.response?.data?.message?.toLocaleLowerCase())
  ) {
    displayError(UNAUTHORIZED_ERROR_MESSAGE);
    goToLogin();
    return true;
  }

  if (status === 401) {
    displayError(UNAUTHORIZED_ERROR_MESSAGE);
    goToLogin();
    return true;
  }

  if (status === 403) {
    if (options.forbiddenErrorCallback) {
      options.forbiddenErrorCallback(error);
    } else {
      goToNotFound();
    }
    return true;
  }
  if (status === 428) {
    goToAccount();
    return true;
  }

  if (
    status === 400 &&
    typeof error?.response?.data === 'string' &&
    error?.response?.data?.includes(
      USER_IS_BLOCKED_ERROR ||
        error?.response?.data
          ?.toLocaleLowerCase()
          .includes(USER_IS_BLOCKED_ERROR)
    )
  ) {
    displayError(USER_IS_BLOCKED_MESSAGE);
    setTimeout(goToLogin, 3000);
    return true;
  }

  return false;
};

const handleGoogleProviderError = (error) => {
  if (
    error === 'The user is not authenticated' &&
    window.location.search.includes('error_description=User+is+not+enabled')
  ) {
    displayError(USER_IS_BLOCKED_MESSAGE);
    goToLogin();
    return true;
  }

  return false;
};

export const handleAWSError = (error) => {
  let message = error?.message;

  if (!message || !error.code) {
    return false;
  }
  if (TOKENS_ERRORS.includes(message.toLocaleLowerCase())) {
    message = UNAUTHORIZED_ERROR_MESSAGE;
  }

  if (
    message.includes(USER_IS_BLOCKED_ERROR) ||
    message.toLocaleLowerCase().includes(USER_IS_BLOCKED_ERROR)
  ) {
    displayError(USER_IS_BLOCKED_MESSAGE);
    return;
  }
  const substr = 'failed with error';
  const subStrIndex = message.indexOf(substr);
  displayError(
    subStrIndex > 0
      ? message.substring(subStrIndex + substr.length + 1)
      : message
  );
  return true;
};

export const reportError = (error, tag = null) => {
  // eslint-disable-next-line no-console
  console.error(error, tag);
};

export const displayError = (displayMessage, options = {}) => {
  toaster.error(t(displayMessage), {
    toastId: 'handle',
    ...options,
  });
};

export const handleError = (
  error,
  displayMessage,
  { report = true, tag = 'handler', options } = {}
) => {
  if (error?.request && error.message === 'Network Error') {
    return displayError(INTERNET_ERROR_MESSAGE);
  }

  if (error?.response?.status && handleHttpError(error, options)) {
    return;
  }

  if (error.name !== 'AxiosError' && handleAWSError(error)) {
    return;
  }

  if (handleGoogleProviderError(error)) {
    return;
  }

  if (error && report) {
    reportError(error, tag);
  }

  if (displayMessage) {
    displayError(displayMessage);
  }
};
