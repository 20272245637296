import logoSrc from 'src/assets/images/atomica logo.svg';

const AtomicaLogo = ({ style = {}, className }) => (
  <img
    src={logoSrc}
    alt="Atomica.ai"
    width="100%"
    height="100%"
    style={{
      aspectRatio: 6.5,
      width: '224px',
      height: '24px',
      maxHeight: '100%',
      maxWidth: '100%',
      ...style,
    }}
    className={className}
  />
);

export default AtomicaLogo;
