import Skeleton from 'react-loading-skeleton';

import styles from './Table.module.scss';

const Table = ({ style }) => {
  return (
    <div
      className={styles.container}
      data-testid="activity-indicator"
      style={style}
    >
      <div className={styles.sidesLine}>
        <Skeleton count={6} />
        <Skeleton count={6} />
      </div>

      <div className={styles.coloredSeperator} />
      <Skeleton count={10} />
    </div>
  );
};

export default Table;
