import { Amplify } from 'aws-amplify';
// import notify from 'src/services/toaster';

const configure = () => {
  const params = new URLSearchParams(window.location.search);
  const clientId = params.get('clientId');

  if (!clientId && !process.env.REACT_APP_CLIENT_ID) {
    // eslint-disable-next-line no-console
    console.error('Please provide client id in url');
    return;
  }

  const awsConfig = {
    oauth: {
      domain: process.env.REACT_APP_OAUTH_DOMAIN,
      redirectSignIn: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_IN,
      redirectSignOut: process.env.REACT_APP_OAUTH_REDIRECT_SIGN_OUT,
      responseType: process.env.REACT_APP_OAUTH_RESPONSE_TYPE,
    },
    Auth: {
      mandatorySignIn: true,
      region: process.env.REACT_APP_REGION,
      userPoolId: process.env.REACT_APP_POOL_ID,
      userPoolWebClientId: clientId || process.env.REACT_APP_CLIENT_ID,
      // userPoolWebClientId: process.env.REACT_APP_CLIENT_ID,
    },
  };

  Amplify.configure(awsConfig);
};

export default {
  configure,
};
