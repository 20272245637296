export const FAILED_PERFORMING_ACTION = 'Failed performing the action';
export const INTERNET_ERROR_MESSAGE = 'You are currently offline';
export const UNHANDLED_ERROR_MESSAGE = 'Oops, something went wrong';
export const NOT_FOUND_ERROR_MESSAGE = 'Page is not found';
export const UNAUTHORIZED_ERROR_MESSAGE =
  'Your session has been expired\n Please login again';
export const FAILED_SEARCH_RESULT = 'Failed getting the search result';
export const FAILED_GETTING_ORDERS = 'Failed to get the orders';
export const FAILED_CREATING_ORDER = 'Failed to create new order';
export const FAILED_UPDATING_ORDER = 'Failed to update the order';
export const FAILED_GETTING_USER_DATA = 'Failed to get user data';
export const FAILED_UPDATING_USER_DATA = 'Failed updating user';
export const FAILED_REMOVING_ACCOUNT = 'Failed to remove account';
export const FAILED_ENABLING_ACCOUNT = 'Failed enabling account';
export const FAILED_INVITING_USER = 'Failed to invite user';
export const FAILED_ADDING_ROLE = 'Failed to add role';
export const FAILED_DISABLING_ACCOUNT = 'Failed disabling account';
export const FAILED_GETTING_USERS = 'Failed to get users';
export const FAILED_GETTING_PROFILE = 'Failed to get profile';
export const FAILED_UPDATING_PROFILE = 'Failed to update profile';
export const FAILED_GETTING_ORDER_LABS = 'Failed to get the labs';
export const FAILED_GETTING_ORDER_DETAILS = 'Failed to get the order details';
export const FAILED_GETTING_ORDER_PRICE = 'Failed to get the order price';
export const FAILED_TOGGLING_SHARING_ORDER =
  'Failed to toggle sharing the order';
export const FAILED_GETTING_FILES = 'Failed to get the file(s)';
export const FAILED_SENDING_FILES = 'Failed to send the file(s)';
export const FAILED_UPLOADING_FILES = 'Failed to upload the file(s)';
export const FAILED_DOWNLOAD_FILES = 'Failed to download the file(s)';
export const FAILED_MOVING_FILES = 'Failed to move the file(s)';
export const FAILED_RESTORING_FILES = 'Failed to restore the file(s)';
export const FAILED_DELETING_FILES = 'Failed to delete file(s)';
export const FAILED_GETTING_ACCOUNT_DETAILS =
  'Failed to get the account details';
export const FAILED_CREATING_PRACTICE = 'Failed to create practice';
export const FAILED_GETTING_MIGRATION_INFORMATION =
  'Failed to get migration information';
export const FAILED_MIGRATING_ACCOUNT = 'Failed to migrate the account';
export const FAILED_UPDATING_ACCOUNT = 'Failed to update the account';
export const FAILED_LOGIN = 'Failed to login';
export const FAILED_SIGNUP = 'Failed to signup';
export const FAILED_LOGOUT = 'Failed to logout';
export const FAILED_CHANGE_PASSWORD = 'Failed to change the password';
export const FAILED_RESTING_PASSWORD = 'Failed to reset the password';
export const FAILED_UPDATING_PASSWORD = 'Failed to update password';
export const FAILED_CONFIRMING = 'Failed to confirm';
export const FAILED_SENDING_CONFIRMATION_CODE =
  'Failed to send confirmation code';
export const FAILED_GETTING_STAFF = 'Failed to get staff';
export const FAILED_GETTING_LABS = 'Failed to get the labs';
export const FAILED_CREATING_LAB = 'Failed to create lab';
export const FAILED_GETTING_CONTACTS = 'Failed to get contacts';
export const FAILED_UPDATING_CONTACTS = 'Failed to update contacts';
export const FAILED_GETTING_ADDRESSES = 'Failed to get addresses';
export const FAILED_UPDATING_ADDRESSES = 'Failed to update addresses';
export const FAILED_GETTING_PRACTICES = 'Failed to get practices';
export const FAILED_GETTING_PRACTICE_DOCTORS = 'Failed to get practice doctors';
export const FAILED_GETTING_QUEUES = 'Failed to get queues';
export const FAILED_CREATING_QUEUE = 'Failed to create queue';
export const FAILED_UPDATING_QUEUE = 'Failed to update queue';
export const FAILED_REMOVING_QUEUE = 'Failed to remove queue';
export const FAILED_GETTING_INDEX = 'Failed to get index';
export const FAILED_UPDATING_INDEX = 'Failed to update index';
export const FAILED_GETTING_SURGICAL_INFO = 'Failed to get surgical info';
export const FAILED_UPDATING_SURGICAL_INFO = 'Failed to update surgical info';
export const FAILED_GETTING_PRACTICE_INFO = 'Failed to get practice info';
export const FAILED_UPDATING_PRACTICE_INFO = 'Failed to get practice info';
export const FAILED_ADDING_ASSOCIATED_LAB = 'Failed to add add lab';
export const FAILED_GETTING_ASSOCIATED_LAB = 'Failed to get associated lab';
export const FAILED_DELETING_ASSOCIATED_LAB = 'Failed to delete associated lab';
export const FAILED_BLOCK_LAB = 'Failed to block lab';
export const FAILED_UN_BLOCK_LAB = 'Failed to unblock lab';
export const FAILED_ADDING_CONTACT_INFO = 'Failed to add contact information';
export const FAILED_ACCEPT_INVITATION = 'Failed to accept invitation';
export const FAILED_RESEND_INVITATION = 'Failed to resend invitation';
export const FAILED_CANCEL_INVITATION = 'Failed to cancel invitation';
export const FAILED_GETTING_DOCTOR_PREFERENCES =
  'Failed to get doctor preferences';

export const TOKENS_ERRORS = [
  'Access token has been revoked',
  'Refresh token has been revoked',
  'Access token has expired',
  'Refresh token has expired ',
];

export const FAILED_INVITING_LAB_TECHNICIAN = 'Failed to invite lab technician';
export const USER_IS_BLOCKED_ERROR = 'User is disabled';
export const USER_IS_BLOCKED_MESSAGE =
  'Your account is blocked, please contact the system administrator';
export const FAILED_LOGIN_TO_SETUP_NEW_PASSWORD_FOR_NEW_USER =
  'Invitation has been expired';
export const INVITATION_IS_EXPIRED = 'Incorrect username or password';
export const INVITATION_IS_BLOCKED =
  'Your account is blocked, please contact the system administrator';
export const FAILED_GETTING_USER_ROLE = 'Failed to get user role';
export const FAILED_GETTING_USER_PERMISSIONS = 'Failed to get user permissions';
export const FAILED_GET_PERMISSIONS = 'Failed to get permissions';
export const FAILED_GET_ROLES = 'Failed to get roles';
export const FAILED_BLOCK_ACCOUNT = 'Failed to block account';
export const FAILED_UNBLOCK_ACCOUNT = 'Failed to unblock account';
export const FAILED_GET_ROLES_PERMISSIONS = 'Failed to get roles permissions';
export const FAILED_GETTING_LAB_PROFILE = 'Failed to get lab profile';
export const FAILED_GETTING_DOCTOR_PROFILE = 'Failed to get doctor profile';
export const FAILED_GETTING_OPTIONS = 'Failed to get options';
export const FAILED_BLOCK_PRACTICE = 'Failed to block this practice';
export const FAILED_UNBLOCK_PRACTICE = 'Failed to unblock this practice';
export const EXPIRED_RESET_PASSWORD_LINK =
  'Expired reset password link, please request a forget password again';
export const INVALID_RESET_PASSWORD_CODE =
  'Invalid reset password link, please request a forget password again';
export const FAILED_UPDATE_ADDRESSES = 'Failed to update address';
export const FAILED_GETTING_USER_TRANSACTIONS =
  'Failed to get user transactions';
export const FAILED_GETTING_USER_RESOURCES = 'Failed to get user resources';
export const FAILED_UPDATING_USER_RESOURCES = 'Failed updating user resources';
export const FAILED_GETTING_RESOURCES = 'Failed to get resources';
export const FAILED_DOWNLOAD_Apps = 'Failed to download app';
export const FAILED_ADDING_TAGS = 'Failed to add tags';
export const FAILED_GETTING_LOGS = 'Failed to get logs';
